import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useExportAuditProductsCsvQuery } from 'src/graphql';
import { useRematch } from 'src/rematch';
import { select } from 'src/rematch/store';
import { buildAuditProductsFilter } from 'src/utils/buildAuditProductsFilter';

const POLL_INTERVAL = 5000; // 5 seconds

interface ExportAuditCsvContextProps {
  /** Is a export csv process currently underway */
  isExportRunning: boolean;

  /** Start the export csv process */
  startExport: () => void;
}

const ExportAuditCsvContext = createContext<ExportAuditCsvContextProps>(
  {} as ExportAuditCsvContextProps
);

export const useExportAuditCsv = () => useContext(ExportAuditCsvContext);

export const ExportAuditCsvProvider = ({ children }: { children: React.ReactNode }) => {
  const storeIds = useRematch(select.restaurants.selectedRestaurants);
  const availability = useRematch(select.auditProducts.getSelectedAvailability);
  const serviceMode = useRematch(select.auditProducts.getSelectedServiceMode);
  const channel = useRematch(select.auditProducts.getSelectedChannel);
  const itemType = useRematch(select.auditProducts.getSelectedType);
  const sectionSelected = useRematch(select.auditProducts.getSelectedSection);
  const storeIdsSelected = useRematch(select.auditProducts.getSelectedRestaurant);

  const [polling, setPolling] = useState(false);

  const filters = buildAuditProductsFilter(
    storeIds,
    storeIdsSelected,
    availability,
    serviceMode,
    channel,
    itemType,
    sectionSelected
  );

  const { data } = useExportAuditProductsCsvQuery({
    variables: {
      filters,
    },
    skip: !polling,
    pollInterval: POLL_INTERVAL,
  });

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'audit_products.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const { downloadUrl } = data?.exportAuditProductsCsv ?? {};

    if (downloadUrl) {
      setPolling(false);
      handleDownload(downloadUrl);
    }
  }, [data?.exportAuditProductsCsv]);

  const api = useMemo(() => {
    const startExport = () => {
      setPolling(true);
    };

    return {
      isExportRunning: polling,
      startExport,
    };
  }, [polling, setPolling]);

  return <ExportAuditCsvContext.Provider value={api}>{children}</ExportAuditCsvContext.Provider>;
};
