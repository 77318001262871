import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import { Maybe, OktaUser, Roles } from 'src/graphql';

import { fetchUserPermissions, setSelectedCountry } from '../utils';

import type { RootModel } from '.';

export interface IUserPermissions {
  allowChangeAvailability: boolean;
  allowChangePrice: boolean;
  allowCopyStorePlus: boolean;
  allowEditor: boolean;
  allowAddUser: boolean;
  allowEditUser: boolean;
  allowDownloadOrders: boolean;
  allowViewOrders: boolean;
  allowAccessDeliverySummary: boolean;
  allowAccessDeliveryRestaurantManagement: boolean;
  allowAccessDeliveryDmpInspector: boolean;
  allowAccessDeliveryComplaintsCompensations: boolean;
  allowAccessDeliveryAreas: boolean;
  allowAccessDeliveryReports: boolean;
  allowAccessDeliveryHistory: boolean;
  allowAccessDeliveryMasterRestaurant: boolean;
  allowViewPaymentMethods: boolean;
  allowEditPaymentMethods: boolean;
  allowViewReloadAuditProducts: boolean;
  isLoggedIn: boolean;
  userFetched: boolean;
  region: IsoCountryCode2Char;
  restaurantIds: Maybe<ReadonlyArray<Maybe<string>>>;
}

export const userPermissions = createModel<RootModel>()({
  state: {} as Partial<IUserPermissions>,
  reducers: {
    setUserPermissions(state, user?: OktaUser) {
      const userFetched = true;
      const isLoggedIn = !!user;
      const region = user?.Country as IsoCountryCode2Char;
      const allowChangeAvailability = user?.Permissions?.includes(Roles.EDIT_PRODUCTS_AVAILABILITY);
      const allowChangePrice = user?.Permissions?.includes(Roles.EDIT_PRODUCTS_PRICE);
      const allowEditor = allowChangeAvailability ?? allowChangePrice;
      const allowAddUser = user?.Permissions?.includes(Roles.ADD_USERS);
      const allowEditUser = user?.Permissions?.includes(Roles.EDIT_USERS);
      const allowDownloadOrders = user?.Permissions?.includes(Roles.DOWNLOAD_ORDERS);
      const allowViewOrders = user?.Permissions?.includes(Roles.VIEW_ORDERS);
      const allowAccessDeliverySummary = user?.Permissions?.includes(Roles.DELIVERY_SUMMARY);
      const allowAccessDeliveryDmpInspector = user?.Permissions?.includes(
        Roles.DELIVERY_DMP_INSPECTOR
      );
      const allowAccessDeliveryRestaurantManagement = user?.Permissions?.includes(
        Roles.DELIVERY_RESTAURANT_MANAGEMENT
      );
      const allowAccessDeliveryComplaintsCompensations = user?.Permissions?.includes(
        Roles.DELIVERY_COMPLAINTS_COMPENSATIONS
      );
      const allowAccessDeliveryAreas = user?.Permissions?.includes(Roles.DELIVERY_AREAS);
      const allowAccessDeliveryReports = user?.Permissions?.includes(Roles.DELIVERY_REPORTS);
      const allowAccessDeliveryHistory = user?.Permissions?.includes(Roles.DELIVERY_HISTORY);
      const allowAccessDeliveryMasterRestaurant = user?.Permissions?.includes(
        Roles.DELIVERY_MASTER_RESTAURANT
      );
      const allowCopyStorePlus = user?.Permissions?.includes(Roles.COPY_STORE_PLUS);
      const allowViewPaymentMethods = user?.Permissions?.includes(Roles.VIEW_PAYMENT_METHODS);
      const allowEditPaymentMethods = user?.Permissions?.includes(Roles.EDIT_PAYMENT_METHODS);
      const allowViewReloadAuditProducts = user?.Permissions?.includes(
        Roles.VIEW_RELOAD_AUDIT_PRODUCTS
      );

      const newState = {
        ...state,
        allowChangeAvailability,
        allowChangePrice,
        allowCopyStorePlus,
        allowEditor,
        allowAddUser,
        allowEditUser,
        allowDownloadOrders,
        allowViewReloadAuditProducts,
        allowViewOrders,
        allowAccessDeliverySummary,
        allowAccessDeliveryRestaurantManagement,
        allowAccessDeliveryDmpInspector,
        allowAccessDeliveryComplaintsCompensations,
        allowAccessDeliveryAreas,
        allowAccessDeliveryReports,
        allowAccessDeliveryHistory,
        allowAccessDeliveryMasterRestaurant,
        allowViewPaymentMethods,
        allowEditPaymentMethods,
        isLoggedIn,
        userFetched,
        region,
        restaurantIds: user?.RestaurantIds,
      };

      return newState;
    },
    setRegion(state, country: IsoCountryCode2Char) {
      return {
        ...state,
        region: country,
      };
    },
    clear(state) {
      return {
        ...state,
        isLoggedIn: false,
        userFetched: false,
      };
    },
  },
  effects: dispatch => ({
    async fetchPermissions(payload: { country: IsoCountryCode2Char; oktaId: string }) {
      try {
        const query = await fetchUserPermissions(payload);
        const user = query.data.users[0] as OktaUser;

        dispatch.userPermissions.setUserPermissions(user);
        dispatch.editor.setUserRegion(user.Country as IsoCountryCode2Char);
      } catch {
        dispatch.userPermissions.setUserPermissions();
        dispatch.userPermissions.setRegion(payload.country);
      }
    },
    async changeRegion(payload: { country: IsoCountryCode2Char; oktaId: string }) {
      setSelectedCountry(payload.country);
      dispatch.userPermissions.clear();
      dispatch.restaurants.clearSelectedRestaurants();
      dispatch.menu.clearSelectedProducts();
      dispatch.editor.clearUpdates();
      dispatch.userPermissions.fetchPermissions(payload);
    },
  }),
  selectors: slice => ({
    allowChangeAvailability() {
      return slice(state => state.allowChangeAvailability);
    },
    allowChangePrice() {
      return slice(state => state.allowChangePrice);
    },
    allowEditor() {
      return slice(state => state.allowEditor);
    },
    allowAddUser() {
      return slice(state => state.allowAddUser);
    },
    allowEditUser() {
      return slice(state => state.allowEditUser);
    },
    allowDownloadOrders() {
      return slice(state => state.allowDownloadOrders);
    },
    allowViewOrders() {
      return slice(state => state.allowViewOrders);
    },
    allowAccessDeliverySummary() {
      return slice(state => state.allowAccessDeliverySummary);
    },
    allowAccessDeliveryRestaurantManagement() {
      return slice(state => state.allowAccessDeliveryRestaurantManagement);
    },
    allowAccessDeliveryDmpInspector() {
      return slice(state => state.allowAccessDeliveryDmpInspector);
    },
    allowAccessDeliveryComplaintsCompensations() {
      return slice(state => state.allowAccessDeliveryComplaintsCompensations);
    },
    allowAccessDeliveryAreas() {
      return slice(state => state.allowAccessDeliveryAreas);
    },
    allowAccessDeliveryReports() {
      return slice(state => state.allowAccessDeliveryReports);
    },
    allowAccessDeliveryHistory() {
      return slice(state => state.allowAccessDeliveryHistory);
    },
    allowAccessDeliveryMasterRestaurant() {
      return slice(state => state.allowAccessDeliveryMasterRestaurant);
    },
    allowAccessDelivery() {
      return slice(state => {
        const permissions = [
          state.allowAccessDeliveryAreas,
          state.allowAccessDeliveryComplaintsCompensations,
          state.allowAccessDeliveryDmpInspector,
          state.allowAccessDeliveryReports,
          state.allowAccessDeliveryRestaurantManagement,
          state.allowAccessDeliverySummary,
          state.allowAccessDeliveryMasterRestaurant,
        ];

        return permissions.some(permission => permission);
      });
    },
    allowCopyStorePlus() {
      return slice(state => state.allowCopyStorePlus);
    },
    allowViewPaymentMethods() {
      return slice(state => state.allowViewPaymentMethods);
    },
    allowViewReloadAuditProducts() {
      return slice(state => state.allowViewReloadAuditProducts);
    },
    allowEditPaymentMethods() {
      return slice(state => state.allowEditPaymentMethods);
    },
    isLoggedIn() {
      return slice(state => state.isLoggedIn);
    },
    userFetched() {
      return slice(state => state.userFetched);
    },
    region() {
      return slice(state => state.region);
    },
    restaurantIds() {
      return slice(state => state.restaurantIds);
    },
  }),
});
