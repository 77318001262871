import { Suspense, lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { BaseLayout } from 'src/pages/shell';
import { useRematch } from 'src/rematch';
import { select } from 'src/rematch/store';

import { Loading, LoadingWrapper } from './components/V2/Loading';

const AnalyticsPage = lazy(() => import('./pages/analytics'));
const DashboardPage = lazy(() => import('./pages/dashboard'));
const UnauthorizedPage = lazy(() => import('./pages/unauthorized'));
const ErrorsPage = lazy(() => import('./pages/errors'));
const EditorPage = lazy(() => import('./pages/editor'));
const GuidesPage = lazy(() => import('./pages/guides'));
const RestaurantsPage = lazy(() => import('./pages/restaurants'));
const DeliveryPage = lazy(() => import('./pages/delivery'));
const RestaurantDetailPage = lazy(() => import('./pages/restaurant-detail'));
const StatusGlossaryPage = lazy(() => import('./pages/status-glossary'));
const OrdersPage = lazy(() => import('./pages/orders'));
const ProductsPage = lazy(() => import('./pages/products'));
const UserManagementPage = lazy(() => import('./pages/users'));
const ProductDetail = lazy(() => import('./pages/product-detail'));
const HistoryPage = lazy(() => import('./pages/history'));
const PaymentMethodsPage = lazy(() => import('./pages/payment-methods'));
const AuditProductsPage = lazy(() => import('./pages/audit-products'));

const App: React.FC = () => {
  const isLoggedIn = useRematch(select.userPermissions.isLoggedIn);
  const userFetched = useRematch(select.userPermissions.userFetched);
  const allowAccessDelivery = useRematch(select.userPermissions.allowAccessDelivery);
  const enableAnalyticsPage = useRematch(select.launchDarkly.enableAnalyticsPage);
  const enableErrorsPage = useRematch(select.launchDarkly.enableErrorsPage);
  const enableGuidesPage = useRematch(select.launchDarkly.enableGuidesPage);
  const enableDeliveryPage = useRematch(select.launchDarkly.enableDeliveryPage);
  const enableUserManagementPage = useRematch(select.launchDarkly.enableUserManagementPage);
  const enableHistoryPage = useRematch(select.launchDarkly.enableHistoryPage);

  if (!userFetched) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (!isLoggedIn) {
    return (
      <BaseLayout>
        <Suspense fallback={null}>
          <Routes>
            <Route path="/" element={<UnauthorizedPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="restaurants/*" element={<RestaurantsPage />} />
          <Route path="restaurants/:id" element={<RestaurantDetailPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/:id/*" element={<ProductDetail />} />
          <Route path="editor/*" element={<EditorPage />} />
          {enableAnalyticsPage && <Route path="analytics" element={<AnalyticsPage />} />}
          <Route path="order-history" element={<OrdersPage />} />
          <Route path="status-glossary" element={<StatusGlossaryPage />} />
          {enableGuidesPage && <Route path="guides" element={<GuidesPage />} />}
          {enableErrorsPage && <Route path="errors" element={<ErrorsPage />} />}
          {enableDeliveryPage && allowAccessDelivery && (
            <Route path="delivery/*" element={<DeliveryPage />} />
          )}
          {enableUserManagementPage && <Route path="users/*" element={<UserManagementPage />} />}
          {enableHistoryPage && <Route path="history" element={<HistoryPage />} />}
          <Route path="payment-methods" element={<PaymentMethodsPage />} />
          <Route path="audit-products" element={<AuditProductsPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </BaseLayout>
  );
};

export default App;
