import { createModel } from '@rematch/core';

import { AuditProductsReportRow, Channel } from 'src/graphql';

import type { RootModel } from '.';

export interface AuditProductsState {
  availability: {
    options: Record<string, string>[];
    selected: string | null;
  };
  serviceMode: {
    options: Record<string, string>[];
    selected: string | null;
  };
  channel: {
    options: Record<string, string>[];
    selected: string | null;
  };
  type: {
    options: Record<string, string>[];
    selected: string[];
  };
  section: {
    options: Record<string, string>[];
    selected: string[];
  };
  restaurant: {
    options: string[];
    selected: string[];
  };
  searchTerm: {
    query: string;
  };
  pagination: {
    start: number;
    end: number;
  };
  products: AuditProductsReportRow[];
}

const channelOptions: Record<string, string>[] = [];

for (const channel in Channel) {
  channelOptions.push({
    key: channel.toLowerCase(),
    value: Channel[channel as keyof typeof Channel],
  });
}

const initialPagination = {
  start: 0,
  end: 50,
};

export const auditProducts = createModel<RootModel>()({
  state: {
    availability: {
      options: [
        { key: 'true', value: 'Available' },
        { key: 'false', value: 'Unavailable' },
      ],
      selected: null,
    },
    serviceMode: {
      options: [
        { key: 'delivery', value: 'Delivery' },
        { key: 'pickup', value: 'Eat in' },
        { key: 'lateNight', value: 'Late Night' },
      ],
      selected: null,
    },
    channel: {
      options: channelOptions,
      selected: null,
    },
    type: {
      options: [
        { key: 'Item', value: 'Item' },
        { key: 'Combo', value: 'Combo' },
        { key: 'Reward', value: 'Reward' },
        { key: 'ConfigOffer', value: 'Config Offer' },
        { key: 'ModifierMultiplier', value: 'Modifier Multiplier' },
        { key: 'SystemwideOffer', value: 'System Wide Offer' },
      ],
      selected: [],
    },
    section: {
      options: [],
      selected: [],
    },
    restaurant: {
      options: [],
      selected: [],
    },
    searchTerm: {
      query: '',
    },
    pagination: {
      start: 0,
      end: 50,
    },
    products: [],
  } as AuditProductsState,

  reducers: {
    setAvailability(state, payload: string | null) {
      return {
        ...state,
        availability: { ...state.availability, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setServiceMode(state, payload: string | null) {
      return {
        ...state,
        serviceMode: { ...state.serviceMode, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setChannel(state, payload: string | null) {
      return {
        ...state,
        channel: { ...state.channel, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setType(state, payload: string[]) {
      return {
        ...state,
        type: { ...state.type, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setSection(state, payload: string[]) {
      return {
        ...state,
        section: { ...state.section, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setRestaurant(state, payload: string[]) {
      return {
        ...state,
        restaurant: { ...state.restaurant, selected: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setRestaurantOptions(state, payload: string[]) {
      return { ...state, restaurant: { ...state.restaurant, options: payload } };
    },
    setSearchTerm(state, payload: string) {
      return {
        ...state,
        searchTerm: { query: payload },
        pagination: initialPagination,
        products: [],
      };
    },
    setPagination(state, payload: { start: number; end: number }) {
      return { ...state, pagination: payload };
    },
    setProducts(state, payload: AuditProductsReportRow[]) {
      return { ...state, products: payload };
    },
    appendProducts(state, payload: AuditProductsReportRow[]) {
      return { ...state, products: [...state.products, ...payload] };
    },
    resetProducts(state) {
      return { ...state, products: [] };
    },
    setSectionOptions(state, payload: Record<string, string>[]) {
      return {
        ...state,
        section: {
          ...state.section,
          options: payload,
        },
      };
    },
  },

  selectors: (slice, createSelector) => ({
    getAvailabilityOptions() {
      return slice(state => state.availability.options);
    },
    getServiceModeOptions() {
      return slice(state => state.serviceMode.options);
    },
    getChannelOptions() {
      return slice(state => state.channel.options);
    },
    getTypeOptions() {
      return slice(state => state.type.options);
    },
    getSectionOptions() {
      return slice(state => state.section.options);
    },
    getRestaurantOptions() {
      return slice(state => state.restaurant.options);
    },
    getSelectedAvailability() {
      return slice(state => state.availability.selected);
    },
    getSelectedServiceMode() {
      return slice(state => state.serviceMode.selected);
    },
    getSelectedChannel() {
      return slice(state => state.channel.selected);
    },
    getSelectedType() {
      return slice(state => state.type.selected);
    },
    getSelectedSection() {
      return slice(state => state.section.selected);
    },
    getSelectedRestaurant() {
      return slice(state => state.restaurant.selected);
    },
    getSearchTerm() {
      return slice(state => state.searchTerm.query);
    },
    getPagination() {
      return slice(state => state.pagination);
    },
    getProducts() {
      return slice(state => state.products);
    },
  }),
});
