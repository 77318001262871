import { AuditProductsReportFilters } from 'src/graphql';

export const buildAuditProductsFilter = (
  storeIds: string[],
  storeIdsSelected: string[] = [],
  availability: string | null = null,
  serviceMode: string | null = null,
  channel: string | null = null,
  itemType: string[] = [],
  sectionSelected: string[] = []
): AuditProductsReportFilters => {
  return {
    storeIds: storeIdsSelected.length > 0 ? storeIdsSelected : storeIds,
    availability: getAvailability(availability),
    serviceMode: serviceMode ?? undefined,
    channel: channel ?? undefined,
    type: itemType.length > 0 ? itemType[0] : undefined,
    section: sectionSelected.length > 0 ? sectionSelected[0] : undefined,
  };
};

const getAvailability = (availability: string | null): boolean | undefined => {
  if (availability === 'true') {
    return true;
  }
  if (availability === 'false') {
    return false;
  }
  return undefined;
};
